<template>
  <div class="p-grid crud-demo">
    <div class="p-col-12">
      <div class="card">
        <Toast />
        <Toolbar class="p-mb-4">
          <template v-slot:left>
            <Button
              label="Novo"
              icon="pi pi-plus"
              class="p-button-success p-mr-2 p-mb*2"
              @click="openNew"
            />
          </template>
        </Toolbar>

        <DataTable
          ref="dt"
          :value="visitantes"
          class="p-datatable-sm p-datatable-striped"
          v-model:selection="selectedVisitantes"
          dataKey="id"
          :paginator="true"
          :rows="10"
          :filters="filters"
          paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
          :rowsPerPageOptions="[5, 10, 25]"
          currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords} visitantes"
        >
          <template #header>
            <div class="table-header">
              <h5 class="p-m-0">Cadastrar Visitantes</h5>
              <span class="p-input-icon-left">
                <i class="pi pi-search" />
                <InputText
                  v-model="filters['global']"
                  placeholder="Pesquisar..."
                />
              </span>
            </div>
          </template>

          <Column field="nome" header="Nome" :sortable="true">
            <template #body="slotProps">
              <span class="p-column-title">Nome</span>
              {{ slotProps.data.nome }}
            </template>
          </Column>
          <Column field="rg" header="RG" :sortable="true">
            <template #body="slotProps">
              <span class="p-column-title">RG</span>
              {{ formatCurrency(slotProps.data.rg) }}
            </template>
          </Column>
          <Column field="cpf" header="CPF" :sortable="true">
            <template #body="slotProps">
              <span class="p-column-title">CPF</span>
              {{ slotProps.data.cpf }}
            </template>
          </Column>
          <Column field="telefone" header="Telefone" :sortable="true">
            <template #body="slotProps">
              <span class="p-column-title">Telefone</span>
              {{ slotProps.data.telefone }}
            </template>
          </Column>
          <Column field="origem" header="Origem" :sortable="true">
            <template #body="slotProps">
              <span class="p-column-title">Origem</span>
              {{ slotProps.data.origem }}
            </template>
          </Column>
          <Column>
            <template #body="slotProps">
              <Button
                icon="pi pi-pencil"
                class="p-button-rounded p-button-success p-mr-2"
                @click="editVisitante(slotProps.data)"
              />
              <Button
                icon="pi pi-trash"
                class="p-button-rounded p-button-warning"
                @click="confirmdeleteVisitante(slotProps.data)"
              />
            </template>
          </Column>
          <template #empty>
            <div class="p-text-center">Nenhum resultado encontrado...</div>
          </template>
        </DataTable>

        <Dialog
          v-model:visible="visitanteDialog"
          :style="{ width: '850px' }"
          :header="titleDialog"
          @hide="hideDialog"
          :modal="true"
          class="p-fluid"
        >
          <div class="p-field">
            <label for="nome">Nome</label>
            <InputText
              id="nome"
              v-model.trim="visitante.nome"
              required="true"
              autofocus
              maxlength="100"
              placeholder="Digite o nome do visitante"
              :class="{ 'p-invalid': submitted && !visitante.nome }"
            />
            <small class="p-invalid" v-if="submitted && !visitante.nome"
              >Nome é obrigatório</small
            >
          </div>
          <div class="p-field">
            <label for="rg">RG</label>
            <InputText
              id="nome"
              v-model.trim="visitante.rg"
              required="true"
              autofocus
              maxlength="15"
              placeholder="Digite o RG do visitante"
              :class="{ 'p-invalid': submitted && !visitante.rg }"
            />
            <small class="p-invalid" v-if="submitted && !visitante.rg"
              >RG é obrigatório</small
            >
          </div>
          <div class="p-field">
            <label for="telefone">Telefone</label>
            <InputMask
              id="telefone"
              v-model.trim="visitante.telefone"
              required="true"
              mask="(99) 99999-9999"
              placeholder="Digite o contato do visitante"
              :class="{ 'p-invalid': submitted && !visitante.telefone }"
            />
            <small class="p-invalid" v-if="submitted && !visitante.telefone"
              >Telefone é obrigatório</small
            >
          </div>
          <div class="p-field">
            <label for="cpf">CPF</label>
            <InputMask
              id="cpf"
              v-model.trim="visitante.cpf"
              required="true"
              mask="999.999.999-99"
              placeholder="Digite o CPF do visitante"
              :class="{ 'p-invalid': submitted && !visitante.cpf }"
            />
            <small class="p-invalid" v-if="submitted && !visitante.cpf"
              >CPF é obrigatório</small
            >
          </div>
          <div class="p-field">
            <label for="origem">Origem</label>
            <InputText
              id="origem"
              v-model.trim="visitante.origem"
              required="true"
              autofocus
              maxlength="50"
              placeholder="Digite a origem do visitante"
              :class="{ 'p-invalid': submitted && !visitante.origem }"
            />
            <small class="p-invalid" v-if="submitted && !visitante.origem"
              >Origem é obrigatório</small
            >
          </div>
          <div class="p-grid">
            <div class="p-col-6">
              <video id="idVideo" class="img-fluid"></video>
            </div>
            <div class="p-col-6">
              <img id="idImg" :src="srcImg" class="img-fluid" />
            </div>
          </div>
          <template #footer>
            <div v-if="initUpload">
              <ProgressBar mode="indeterminate" />
            </div>
            <div v-else>
              <Button
                label="Capturar"
                icon="pi pi-camera"
                class="p-button-primary"
                @click="takePicture()"
              />
              <Button
                label="Salvar"
                icon="pi pi-check"
                class="p-button-success"
                :disabled="!srcImg"
                @click="sendVisitante"
              />
              <Button
                label="Cancelar"
                icon="pi pi-times"
                class="p-button-danger"
                @click="hideDialog"
              />
            </div>
          </template>
        </Dialog>

        <Dialog
          v-model:visible="deleteVisitanteDialog"
          :style="{ width: '450px' }"
          header="Mensagem de Confirmação!"
          :modal="true"
        >
          <div class="confirmation-content">
            <i
              class="pi pi-exclamation-triangle p-mr-3"
              style="font-size: 2rem"
            />
            <span v-if="visitante"
              >Tem certeza de que deseja excluir o registro
              <b>{{ visitante.name }}</b
              >?</span
            >
          </div>
          <template #footer>
            <Button
              label="Sim"
              icon="pi pi-check"
              class="p-button-raised"
              @click="deleteVisitante"
            />
            <Button
              label="Não"
              icon="pi pi-times"
              class="p-button-raised p-button-text"
              @click="deleteVisitanteDialog = false"
            />
          </template>
        </Dialog>

        <Dialog
          v-model:visible="deleteVisitantesDialog"
          :style="{ width: '450px' }"
          header="Mensagem de Confirmação!"
          :modal="true"
        >
          <div class="confirmation-content">
            <i
              class="pi pi-exclamation-triangle p-mr-3"
              style="font-size: 2rem"
            />
            <span v-if="visitante"
              >Tem certeza de que deseja excluir os registros selecionados
              ?</span
            >
          </div>
          <template #footer>
            <Button
              label="Sim"
              icon="pi pi-check"
              class="p-button-raised"
              @click="deleteSelectedVisitantes"
            />
            <Button
              label="Não"
              icon="pi pi-times"
              class="p-button-raised p-button-text"
              @click="deleteVisitantesDialog = false"
            />
          </template>
        </Dialog>
      </div>
    </div>
  </div>
</template>

<script>
import Moment from "moment";
import AuthService from "@/service/Auth/AuthService";
import VisitanteService from "@/service/Visitante/VisitanteService";
export default {
  data() {
    return {
      visitantes: null,
      visitanteDialog: false,
      deleteVisitanteDialog: false,
      deleteVisitantesDialog: false,
      storageRef: null,
      cpfAtual: null,
      visitante: {
        id: null,
        url: null,
        nome: null,
        rg: null,
        cpf: null,
        telefone: null,
        origem: null,
      },
      selectedVisitantes: null,
      filters: {},
      submitted: false,
      titleDialog: null,
      msg: null,
      msgUsuario: null,
      msgDesenvolvedor: null,
      stream: null,
      srcImg: null,
      initUpload: false,
    };
  },
  visitanteService: null,
  authService: null,
  created() {
    this.authService = new AuthService();
    this.visitanteService = new VisitanteService();
    this.storageRef = this.$firebaseApp.storage();
  },
  mounted() {
    this.authService.checarToken(this.listarVisitantes());
  },
  methods: {
    listarVisitantes() {
      this.visitanteService.listar().then((data) => {
        this.visitantes = data;
      });
    },
    formatarDatas(date) {
      if (date) {
        return Moment(date).calendar();
      }
      return;
    },
    formatCurrency(value) {
      if (value)
        return value.toLocaleString("en-US", {
          style: "currency",
          currency: "USD",
        });
      return;
    },
    openNew() {
      this.titleDialog = "Novo visitante";
      this.submitted = false;
      this.visitanteDialog = true;
      this.playVideo();
    },
    hideDialog() {
      this.visitanteDialog = false;
      this.submitted = false;
      this.visitante = {};
      this.srcImg = null;
      this.stopVideo();
    },
    sendVisitante() {
      this.initUpload = true;
      if (this.srcImg == this.visitante.url) this.saveVisitante();
      else this.onUpload();
    },
    onDeleteFoto() {
      this.storageRef.ref().child(`visitantes/${this.visitante.cpf}`).delete();
    },
    onUpload() {
      this.storageRef
        .ref()
        .child("visitantes/" + this.visitante.cpf)
        .putString(this.srcImg, "data_url")
        .then((snapshot) => {
          this.getDownloadURL(snapshot);
        });
    },
    getDownloadURL(snapshot) {
      snapshot.ref.getDownloadURL().then((downloadURL) => {
        this.visitante.url = downloadURL;
        this.$toast.add({
          severity: "success",
          summary: "Sucesso!",
          detail: "Upload feito!",
          life: 3000,
        });
        this.authService.checarToken(this.saveVisitante());
      });
    },
    saveVisitante() {
      this.submitted = true;
      if (this.visitante.id == null) {
        this.visitanteService
          .criar(this.visitante)
          .then((data) => {
            this.initUpload = false;
            if (data.status === 201) {
              this.$toast.add({
                severity: "success",
                summary: "Sucesso",
                detail: "Registro salvo com sucesso!",
              });
              this.visitantes.push(data.data);
              this.hideDialog();
            }
          })
          .catch((error) => {
            this.initUpload = false;
            this.msgUsuario = error.response.data[0].mensagemUsuario;
            this.msgDesenvolvedor =
              error.response.data[0].mensagemDesenvolvedor;
            console.log(this.msgDesenvolvedor);
            this.$toast.add({
              severity: "error",
              summary: "Erro",
              detail: this.msgUsuario,
            });
          });
      } else {
        this.visitanteService
          .atualizar(this.visitante.id, this.visitante)
          .then((data) => {
            this.initUpload = false;
            if (data.status === 200) {
              this.$toast.add({
                severity: "success",
                summary: "Sucesso",
                detail: "Registro atualizado com sucesso!",
              });
              this.initUpload = false;
              this.hideDialog();
            }
          });
      }
    },
    editVisitante(visitante) {
      this.visitante = visitante;
      this.cpfAtual = this.visitante.cpf;
      this.srcImg = visitante.url;
      this.titleDialog = this.visitante.nome;
      this.visitanteDialog = true;
      this.playVideo();
    },
    confirmdeleteVisitante(visitante) {
      this.visitante = visitante;
      this.deleteVisitanteDialog = true;
    },
    deleteVisitante() {
      this.onDeleteFoto();
      this.authService.checarToken(this.deletarVisitante());
    },
    deletarVisitante() {
      this.visitanteService.remover(this.visitante.id).then((data) => {
        if (data.status === 204) {
          this.deleteVisitanteDialog = false;
          this.visitante = {};
          this.$toast.add({
            severity: "success",
            summary: "Sucesso!",
            detail: "Registro Deletado.",
            life: 3000,
          });
          this.listarVisitantes();
        } else {
          console.log(data);
          this.$toast.add({
            severity: "erro",
            summary: "Erro!",
            detail: "Erro ao deletar registro.",
            life: 3000,
          });
        }
      });
    },
    exportCSV() {
      this.$refs.dt.exportCSV();
    },
    confirmDeleteSelected() {
      this.deleteVisitantesDialog = true;
    },
    deleteSelectedVisitantes() {
      for (var i in this.selectedVisitantes) {
        this.visitante = this.selectedVisitantes[i];
        this.deleteVisitante();
      }
      this.deleteVisitantesDialog = false;
    },
    playVideo() {
      navigator.mediaDevices
        .getUserMedia({
          video: true,
        })
        .then((stream) => {
          this.stream = stream;
          const video = document.querySelector("#idVideo");
          video.srcObject = this.stream;
          video.play();
        })
        .catch((error) => {
          console.error(error);
        });
    },
    stopVideo() {
      if (this.stream != null) {
        this.stream.getTracks().forEach((track) => {
          track.stop();
        });
        this.stream = null;
      }
    },
    takePicture(mimeType = "image/jpeg") {
      let canvas = document.createElement("canvas");
      const video = document.querySelector("#idVideo");
      canvas.setAttribute("height", video.videoHeight);
      canvas.setAttribute("width", video.videoWidth);
      let context = canvas.getContext("2d");
      context.drawImage(video, 0, 0, canvas.width, canvas.height);
      this.srcImg = canvas.toDataURL(mimeType);
    },
  },
};
</script>

<style scoped lang="scss">
#nome,#origem {
  text-transform: uppercase;
}

.img-fluid {
  max-width: 100%;
  height: auto;
}
.table-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.visitante-image {
  width: 100px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}
.p-dialog .visitante-image {
  width: 150px;
  margin: 0 auto 2rem auto;
  display: block;
}
.confirmation-content {
  display: flex;
  align-items: center;
  justify-content: center;
}
.visitante-badge {
  border-radius: 2px;
  padding: 0.25em 0.5rem;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 12px;
  letter-spacing: 0.3px;
  &.status-instock {
    background: #c8e6c9;
    color: #256029;
  }
  &.status-outofstock {
    background: #ffcdd2;
    color: #c63737;
  }
  &.status-lowstock {
    background: #feedaf;
    color: #8a5340;
  }
}
.p-datatable-sm .p-datatable-tbody > tr > td .p-column-title {
  display: none;
}
@media screen and (max-width: 40em) {
  ::v-deep(.p-datatable) {
    &.p-datatable-sm {
      .p-datatable-thead > tr > th,
      .p-datatable-tfoot > tr > td {
        display: none !important;
      }
      .p-datatable-tbody > tr > td {
        text-align: left;
        display: block;
        width: 100%;
        float: left;
        clear: left;
        border: 0 none;
        .p-column-title {
          padding: 0.4rem;
          min-width: 30%;
          display: inline-block;
          margin: -0.4em 1em -0.4em -0.4rem;
          font-weight: bold;
        }
        &:last-child {
          border-bottom: 1px solid var(--surface-d);
          text-align: right;
        }
        .p-rating {
          display: inline-block;
        }
      }
    }
  }
}
</style>